import * as React from "react"
import { Link } from "gatsby";
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"

const MonstapalsPage = () => {

  return (
    <Layout>
      <Seo 
        title="Dew Privacy Policy"
        description="Our privacy policy and data usage declaration for Dew"
      />

    <article className="flex flex-col items-center justify-between mx-auto mb-10 rounded-md max-w-prose">
        <h1 className="w-full mb-6 text-3xl font-light text-center text-gray-600">Dew - Privacy Policy</h1>
        <p className="text-gray-500">Updated 29th January 2022</p>
        <div className="p-6 mt-8 bg-white border border-gray-100 lg:p-16">
            <div className="prose text-gray-600">
                <h2 className="text-2xl font-bold">iOS, watchOS and tvOS</h2>
                <p>We take your privacy extremely seriously. </p>
                <p>
                  Dew does not collect any information related to you or your usage of the application. 
                  All information is kept strictly between you and Hydrawise.
                </p>
                <p>
                  We do not use any third party analytics or data collection services on the app and no
                  information is transmitted to servers other than querying Hydrawise with your account credentials.
                </p>
                <p>
                  Authentication is carried out using <a href="https://en.wikipedia.org/wiki/OAuth">OAuth</a> with
                  permission from Hydrawise. The user session is local to your device.
                </p>
                <p>
                  Dew uses a private iCloud database to transfer information between your Apple Watch and iPhone, this
                  is an encrypted database available to your iCloud account.
                </p>
                <p>
                  Our aim is to bring a delightful native experience to Apple platforms while keeping your privacy intact.
                </p>
                <p>
                  Please see our <Link to="/contact">contact information</Link> and get in touch if you have any questions.
                </p>
            </div>
        </div>
    </article>
    </Layout>
 );
};
export default MonstapalsPage
